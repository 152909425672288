import Vue from "vue";
import VueI18n from "vue-i18n";
import { LANG, SessionHelper } from "./services/Session";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function getInitialLocale() {
  return (
    process.env.VUE_APP_I18N_LOCALE || SessionHelper.getCookie(LANG) || "en"
  );
}

const i18n = new VueI18n({
  locale: getInitialLocale(),
  fallbackLocale: getInitialLocale(),
  messages: loadLocaleMessages(),
});

const loadedLanguages = ["en"];

export async function loadLocaleMessagesAsync(locale) {
  if (loadedLanguages.length > 0 && i18n.locale === locale) {
    return Promise.resolve(locale);
  }

  // If the language was already loaded
  if (loadedLanguages.includes(locale)) {
    i18n.locale = locale;
    return Promise.resolve(locale);
  }

  // If the language hasn't been loaded yet
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `@/locales/${locale}.json`
  );
  i18n.setLocaleMessage(locale, messages.default);
  loadedLanguages.push(locale);
  i18n.locale = locale;
  return Promise.resolve(locale);
}

loadLocaleMessagesAsync(getInitialLocale());

export default i18n;
