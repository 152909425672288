import VueCookie from 'vue-cookies'
// import Store from '../store'
// import  ApiCaller from './apicaller'
// import constants from './constants'
// session kyes
export const X_AUTH_TOKEN = 'X-Auth-Token'
export const X_AUTH_ID = 'X-Auth-Id'
export const JWT_TOKEN = 'JWT-TOKEN'
export const Authorization = 'Authorization'
export const APP_NAME = 'app-name'
export const CONTENT_TYPE = 'Content-Type'
export const LANG = 'lang'
export const LATITUDE = 'latitude'
export const LONGITUDE = 'longitude'
export const MINITYPE = "miniType"
// creating session class
export class SessionHelper {
    // save general data in cookie
    static setCookie(_key, _value) {
        VueCookie.set(_key, _value)
    }

    // get general data in cookie
    static getCookie(_key) {
        return VueCookie.get(_key) || null
    }

    // delete cookie
    static clearCookie(_key) {
        SessionHelper.setCookie(_key, null)
    }

    // refeshing jwt token
    // static refreshJwtToken() {
    //     try {
    //         _JWT.refreshToken = res => {
    //         if (res != 'fail') {
    //             SessionHelper.setCookie(JWT_TOKEN, res)
    //         } else {
    //             SessionHelper.setCookie(JWT_TOKEN, null)
    //         }
    //     }
    //         JSBridgePlugin.refreshTokens('_JWT.refereshToken')
    //     } catch (err) {
    //         console.log('js err',err)
    //         SessionHelper.setCookie(JWT_TOKEN, null)
    //         // SessionHelper.setCookie(JWT_TOKEN, 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI4MzQ5MzUxNzEyIiwiWC1BdXRoLUlEIjoiYjNmMDQzNjZiNzJhMDk5MTNjNDMyYjBjMmExMjY2ZDkiLCJpYXQiOjE1OTMwOTA1MDgsImV4cCI6MTU5MzA5MTQwOH0.UzmFgRRgMfi4tPitAbYg2uuvllRKaS4qodNIp1sMPI4')
    //         // token = null
    //     }
    // }


    // fetching header
    static getHeader(contentType = 'application/json') {
        // 'user_id':  SessionHelper.getCookie(X_AUTH_ID) in ucase use detail
        return {
            [CONTENT_TYPE]: contentType,
            [X_AUTH_ID]: SessionHelper.getCookie(X_AUTH_ID),
            [Authorization]: `Bearer ${SessionHelper.getCookie(JWT_TOKEN)}`,
        }
    }
}
