const Splash = () =>
  import(/* webpackChunkName: "splash" */ '@/views/Splash.vue');

const Dashboard = () =>
  import(/* webpackChunkName: "home" */ '@/views/Dashboard.vue');

const UserProfile = () =>
  import(/*webpackChunkName:"user-profile" */ '@/views/UserProfile');

const LeadGenerate = () =>
  import(/*webpackChunkName:"lead-generate"*/ '@/views/LeadGenerate');

const EarningHistory = () =>
  import(/*webpackChunkName:"earning-history"*/ '@/views/EarningHistory');

const Notifications = () =>
  import(/*webpackChunkName:"notifications"*/ '@/views/Notifications');

const QrCodeDownloadList = () =>
  import(
    /*webpackChunkName:"qr-code-download-list"*/ '@/views/QrCodeDownloadList'
  );

const RegistrationForm = () =>
  import(/*webpackChunkName:"registration-form"*/ '@/views/RegistrationForm');
const PaymentForm = () =>
  import(/*webpackChunkName:"payment-form"*/ '@/views/PaymentForm');

const Return91 = () => import('@/views/Return91');
const OrderToBePickedFromCustomer = () =>
  import(
    /* webpackChunkName: "to-be-picked-order" */ '@/views/OrderToBePickedFromCustomer'
  );

const ReturnOrders = () => import('@/views/ReturnOrders.vue');

const PartnerStatus = () => import('@/views/PartnerStatus.vue');
const ReturnOrdersTracking = () => import('@/views/ReturnOrdersTracking');
const LotOrders = () => import('@/views/LotOrders');
const MyTasks = () => import('@/components/task/MyTasks');
const Login = () => import('@/components/Login');
const QrCode = () => import('@/views/QrCode');
const Shops = () => import('@/views/Shops');
const PartnerFranchise = () => import('@/views/PartnerFranchise');
const VerificationShopForm = () => import('@/views/VerificationForm.vue');
const TaskDetail = () => import('@/views/TaskDetail.vue');
const Test = ()=> import('@/views/Test.vue')
PartnerFranchise;

import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {path:'/test',
  name:'TEST',
  component:Test
},
  {
    path: '/',
    name: 'Splash',
    component: Splash,
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },

  {
    path: '/user-profile',
    name: 'UserProfile',
    component: UserProfile,
  },

  {
    path: '/lead-generate',
    name: 'LeadGenerate',
    component: LeadGenerate,
  },
  {
    path: '/earning-history',
    name: 'EarningHistory',
    component: EarningHistory,
  },

  {
    path: '/lot-orders/:id',
    component: LotOrders,
  },

  {
    path: '/user/:id',
    name: 'Login',
    component: Login,
  },

  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
  },
  {
    path: '/qr-code-download-list',
    name: 'QrCodeDownloadList',
    component: QrCodeDownloadList,
  },
  {
    path: '/sign-up',
    name: 'RegistrationForm',
    component: RegistrationForm,
  },
  {
    path: '/payment-form',
    name: 'PaymentForm',
    component: PaymentForm,
  },
  {
    path: '/return91',
    name: 'Return91',
    component: Return91,
  },

  {
    name: 'OrderToBePickedFromCustomer',
    path: '/order-to-be-picked-from-customer',
    component: OrderToBePickedFromCustomer,
    isPublic: false,
    props: true,
  },

  {
    name: 'ReturnOrders',
    path: '/return-orders',
    component: ReturnOrders,
    isPublic: false,
    props: true,
  },

  {
    path: '/return-orders-tracking',
    component: ReturnOrdersTracking,
    isPublic: false,
    props: true,
  },

  {
    path: '/partner',
    component: PartnerStatus,
  },

  {
    path: '/my-tasks',
    component: MyTasks,
  },
  {
    path: '/qr-code',
    component: QrCode,
  },
  {
    path: '/shops',
    component: Shops,
  },

  {
    path: '/partner-franchise',
    component: PartnerFranchise,
  },

  {
    path: '/verify-shop',
    component: VerificationShopForm,
  },
  {
    path: '/task-detail',
    component: TaskDetail,
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
