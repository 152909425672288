import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    partnerTasks: null,
    partnerStatus: null,
    userInfo: null,
    shopDetails: null,
    taskDetails: null,
    networkDetails: null,
  },
  getters: {
    getPartnerStatus(state) {
      return state.partnerStatus;
    },
    getUserInfo(state) {
      return state.userInfo || {};
    },
    getPartnerTasks(state) {
      return state.partnerTasks;
    },
    getShopDetails(state) {
      return state.shopDetails;
    },

    getNetworkDetails(state) {
      return state.networkDetails;
    },
  },
  mutations: {
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setPartnerTasks(state, payload) {
      state.partnerTasks = payload;
    },
    setPartnerStatus(state, { status, payment }) {
      state.partnerStatus = {};
      state.partnerStatus.status = status;
      state.partnerStatus.payment = payment;
    },
    setShopDetails(state, payload) {
      state.shopDetails = payload;
    },
    setTaskDetails(state, payload) {
      state.taskDetails = payload;
    },

    setNetworkDetails(state, payload) {
      state.networkDetails = payload;
    },
  },
  actions: {},
  modules: {},
});
