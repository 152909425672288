import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueQuagga from "vue-quaggajs";
import VueSweetAlert2 from "vue-sweetalert2";
import VueCookies from "vue-cookies";
import "sweetalert2/dist/sweetalert2.min.css";

Vue.config.productionTip = false;
import i18n from "./i18n";
window.__native_money91 = {};

Vue.use(VueQuagga);
Vue.use(VueSweetAlert2);
Vue.use(VueCookies);
window._getCamera = {};
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
